<script setup lang="ts">
import type { PlanRowView } from '~/modules/plan'

const loading = ref(true)
const plans = ref<PlanRowView[] | null>(null)

onMounted(async () => {
  try {
    plans.value = await getPlans({
      zipCode: forms.dentalAllowance24?.zipcode,
      carrier_groups: [],
      amount: 100,
      starting_from: 0,
    })
  } catch (error) {}

  loading.value = false
})
</script>

<template>
  <Spinner v-if="loading" class="mx-auto" />
  <template v-else-if="!plans">
    <div class="text-xl text-red text-center font-medium">
      Click for More Options
    </div>

    <MediaAlphaAd placementId="xB51YFMkBb4D9ycHYv9YNkBUyjbbUA" :user="{}" />
  </template>
  <template v-else>
    <div class="text-xl text-red text-center font-medium">
      <template v-if="plans.length"> {{ plans.length }} Plans Found </template>
      <template v-else> No Plans Found </template>
    </div>

    <div class="flex items-center justify-center space-x-4">
      <div class="relative flex w-4 h-4">
        <div
          class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"
        >
        </div>
        <div class="relative inline-flex rounded-full h-4 w-4 bg-green-500">
        </div>
      </div>
      <div class="text-xl font-medium">
        Your licensed insurance <br />
        agent is ready to help
      </div>
    </div>

    <ClickToCall :props="{ ctcLocation: 'last-step' }">
      <template #text>
        Call {{ phone.formatted }} <br />
        TTY 711, 24/7
      </template>
    </ClickToCall>
  </template>
</template>
