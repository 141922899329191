<template>
  <FormProgress class="!mb-6" />

  <div class="text-2xl sm:text-3xl text-center">
    <span class="text-red">Looks good!</span>
    We found Medicare Advantage plans in
    <span class="whitespace-nowrap">your area!</span>
  </div>

  <div class="text-xl sm:text-2xl text-center">
    Call to Speak with a
    <span class="whitespace-nowrap">Licensed Insurance Agent</span>
  </div>

  <div class="space-y-2">
    <ClickToCall :props="{ ctcLocation: 'last-step' }" tty />
    <div class="text-center"> Monday-Friday 8am-7pm </div>
  </div>
</template>
