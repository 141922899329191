<script setup lang="ts">
const loading = ref(true)
const planCount = ref(0)

onMounted(async () => {
  try {
    const plans = await getPlans({
      zipCode: forms.medicarePremiumReductionSimple2096?.zipcode,
      amount: 100,
      starting_from: 0,
    })
    planCount.value = plans.length
  } catch {}
  loading.value = false
})
</script>

<template>
  <Spinner v-if="loading" class="mx-auto" />
  <template v-else>
    <div
      v-if="planCount === 0"
      class="text-xl sm:text-2xl text-red text-center font-medium"
    >
      No Plans Found
    </div>
    <div v-else class="text-xl sm:text-2xl text-red text-center font-medium">
      {{ planCount }} Plans Found
    </div>

    <div class="text-xl sm:text-2xl text-center font-medium">
      <span v-if="planCount === 0">But don't worry!</span>
      Continue w/ Licensed
      <span class="whitespace-nowrap">Insurance Agent</span>
    </div>

    <ClickToCall :props="{ ctcLocation: 'last-step' }">
      <template #text>
        Click to Call
        <br />
        TTY 711, 24/7
      </template>
    </ClickToCall>
  </template>
</template>
