<script setup lang="ts">
const minOtcLtd = computed(() => {
  return (
    states.find((state) => state.code === ip.lookup?.stateCode)?.minOtcLtd ??
    500
  )
})
</script>

<template>
  <Layout header="2025" footer="2025">
    <template #banner>
      <Banner> Have Medicare and Medicaid? </Banner>
    </template>
    <div class="container max-w-2xl py-8 space-y-6 text-lg">
      <h1 class="text-2xl font-bold text-center">
        Find Ways to Help Reduce High Grocery Bills: See If You Are Eligible For
        a Medicare Advantage D-SNP plan with ${{ minOtcLtd }} per year or more
        in Allowance to Use Towards Groceries from Well-Known Carriers like
        Humana and UnitedHealthcare®
      </h1>

      <img src="../../assets/advertorial-hero.jpg" />

      <div>
        Are you feeling the pinch with everyday expenses like
        <b> groceries and other expenses? </b>
      </div>

      <div>
        You're not alone! Millions of Americans are seeing rising food prices.
        <b>
          But there could be some help available for Medicare beneficiaries that
          may be eligible for select Medicare Advantage plans with a Medicare
          food allowance benefit.
        </b>
      </div>

      <img
        src="../../assets/advertorial-food-prices-inflation.webp"
        class="py-4"
      />

      <div>
        <b>
          Imagine finding a Medicare Advantage Plan with benefits like ${{
            minOtcLtd
          }}
          per year or more in allowances
        </b>
        to help pay for your grocery shopping.
      </div>

      <div>
        No need to imagine much longer because some
        <b> Medicare Advantage D-SNP plans already offer this benefit. </b>
      </div>

      <div>
        If you're on Medicare and Medicaid, and you're not using a Medicare
        Advantage plan with an Allowance towards Healthy Food.
      </div>

      <div>
        <b>
          And it's so easy to check which Medicare Advantage plans with this
          benefit are available in your area.
        </b>
      </div>

      <div class="bg-yellow-100 border border-yellow-500 p-4 rounded-md">
        Dial
        <ClickToCall v-slot="{ handleClick }">
          <Link
            class="!text-blue-700 font-medium"
            :to="phone.uri"
            @click="handleClick"
          >
            {{ phone.formatted }}
          </Link>
        </ClickToCall>
        TTY 711, 24/7, for a free, no obligation chat with a licensed insurance
        agent. There's zero obligation to sign up, and the average wait time is
        <b>just 2 to 3 minutes.</b>
      </div>

      <div>
        They'll show you different Medicare Advantage Plans and see if you are
        eligible to enroll in a D-SNP plan with an Allowance towards Healthy
        Food.
      </div>

      <div>
        Don't let rising food costs eat into your budget.
        <b>
          Call now and see if you are eligible for a Medicare Advantage Plan
          with ${{ minOtcLtd }} per year or more in Allowances to use towards
          groceries with some Medicare Advantage Plans.
        </b>
      </div>

      <div
        class="-mx-4 [box-shadow:0_0_6px_rgba(0,0,0,0.3)] sm:rounded sm:mx-0 bg-white"
      >
        <Form
          id="groceryAllowanceR2"
          class="!max-w-3xl p-4"
          scrollBehavior="keepPos"
          :steps="[
            { id: 'medicareab' },
            { id: 'us' },
            { id: 'wantDsnp' },
            { id: 'loading' },
            { id: 'call', completed: true },
          ]"
          product="medicare"
        />
      </div>
    </div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "brands": ["bh", "m10"],
    "robots": "noindex",
    "smid": {
      "bh": "MULTI-PLAN_HLWEB0924256_M",
      "m10": "MULTI-PLAN_HLWEB0624208_M"
    }
  }
}
</route>
